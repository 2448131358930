import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  restaurantTicketsHistoryApiCreate,
  restaurantTicketsHistoryApiRead,
  restaurantTicketsHistoryApiUpdate,
  restaurantTicketsHistoryApiDelete,
} from '@/services/restarantTicketsHistory';

const store = new StoreCRUD('restaurantTicketsHistory', {
  create: restaurantTicketsHistoryApiCreate,
  read: restaurantTicketsHistoryApiRead,
  update: restaurantTicketsHistoryApiUpdate,
  delete: restaurantTicketsHistoryApiDelete,
});

export const restaurantTicketsHistoryStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
