import { api } from '@/consts';
import { isEmpty, template } from 'lodash';
import { copy } from 'gantt';

const capitalize = (text: string) =>
  text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
const pluralize = require('pluralize');

class StoreCRUD {
  public state: object = {};
  public getters: object = {};
  public mutations: object = {};
  public actions: object = {};
  private pName: string = ''; // Pluralized name
  private sName: string = ''; // Singularized name
  private services: object = {};

  constructor(name: string, services: object = {}) {
    this.pName = pluralize(name);
    this.sName = pluralize.singular(name);
    this.services = services;
    this.createState();
    this.createGetters();
    this.createMutations();
    this.createActions();
  }

  private createState() {
    const { pName, sName, services } = this;
    this.state = {
      ...this.state,
      pName,
      sName,
      services,
      isLoading: false,
      error: null,
      // data: {},
      [this.pName]: [],
    };
  }

  private createGetters() {
    this.getters = {
      ...this.getters,
      isLoading(state) {
        return state.isLoading;
      },
      hasError(state) {
        return state.error !== null;
      },
      error(state) {
        return state.error;
      },
      [`has${capitalize(this.pName)}`]: (state) => {
        return state[state.pName].length > 0;
      },
      // data(state) {
      //   return state.data;
      // },
      [this.pName]: (state) => {
        return state[state.pName];
      },
      // eg: getAddresses()
      [`get${capitalize(this.pName)}`]: (state: any) => {
        return state.kanbans;
      },
      // eg: getAddressById()
      [`get${capitalize(this.sName)}ById`]: (state: any) => (id: number) => {
        return state[this.pName].find((o: any) => o.id === id);
      },
      // eg: getAddressesByIds()
      [`get${capitalize(this.pName)}ByIds`]: (state: any) => (
        ids: number[],
      ) => {
        return state[this.pName].filter((o) => ids.includes(o.id));
      },
    };
  }

  private createMutations() {
    this.mutations = {
      ...this.mutations,
      [`CREATING_${this.sName.toUpperCase()}`](state) {
        state.isLoading = true;
        state.error = null;
      },
      [`CREATING_${this.sName.toUpperCase()}_SUCCESS`]: (state, item) => {
        state.isLoading = false;
        state.error = null;
        const keys = Object.keys(item);
        state[state.pName] = [...state[state.pName], item[keys[0]]];
        // state[state.pName].unshift(item);
      },
      [`CREATING_${this.sName.toUpperCase()}_ERROR`](state, error) {
        state.isLoading = false;
        state.error = `${error.response.errors[0].message}.`;
      },
      [`READING_${this.pName.toUpperCase()}`]: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      [`READING_${this.pName.toUpperCase()}_SUCCESS`]: (state, items) => {
        state.isLoading = false;
        state.error = null;
        const keys = Object.keys(items);
        state[state.pName] = items[keys[0]];
      },
      [`READING_${this.pName.toUpperCase()}_ERROR`]: (state, error) => {
        state.isLoading = false;
        state.error = `${error.message}.`;
      },
      [`UPDATING_${this.sName.toUpperCase()}`]: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      [`UPDATING_${this.sName.toUpperCase()}_SUCCESS`](state, item) {
        state.isLoading = false;
        state.error = null;
        const keys = Object.keys(item);
        const itemObject = item[keys[0]];
        if (
          typeof state[state.pName] === 'object' &&
          Array.isArray(state[state.pName])
        ) {
          const newArray = [...state[state.pName]];
          const index = newArray.findIndex(
            (arrItem) => arrItem.id === itemObject.id,
          );
          if (index > -1) {
            newArray.splice(index, 1, itemObject);
          }
          return (state[state.pName] = newArray);
        }
        return (state[state.pName] = itemObject);
      },
      [`UPDATING_${this.sName.toUpperCase()}_ERROR`](state, error) {
        state.isLoading = false;
        state.error = `${error.response.errors[0].message}.`;
      },
      [`DELETING_${this.sName.toUpperCase()}`]: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      [`DELETING_${this.sName.toUpperCase()}_SUCCESS`](state) {
        state.isLoading = false;
        state.error = null;
      },
      [`DELETING_${this.sName.toUpperCase()}_ERROR`](state, error) {
        state.isLoading = false;
        state.error = `${error.response.errors[0].message}.`;
      },
    };
  }

  private createActions() {
    this.actions = {
      ...this.actions,
      create: ({ commit, state }, params = null) => {
        commit(`CREATING_${state.sName.toUpperCase()}`, params);
        return new Promise((resolve, reject) => {
          return state.services['create'](params)
            .then((res) => {
              commit(
                `CREATING_${state.sName.toUpperCase()}_SUCCESS`,
                res.data ? (res.data.data ? res.data.data : res.data) : res,
              );
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
              commit(
                `CREATING_${state.sName.toUpperCase()}_ERROR`,
                JSON.parse(JSON.stringify(err)),
              );
              reject(JSON.parse(JSON.stringify(err)));
            });
        });
      },
      read: ({ commit, state }, params = null) => {
        commit(`READING_${state.pName.toUpperCase()}`);
        return new Promise((resolve, reject) => {
          return state.services['read'](params)
            .then((res) => {
              // commit(`READING_${state.pName.toUpperCase()}_SUCCESS`, res.data);
              // resolve(res.data);
              commit(`READING_${state.pName.toUpperCase()}_SUCCESS`, res);
              resolve(res);
            })
            .catch((err) => {
              commit(
                `READING_${state.pName.toUpperCase()}_ERROR`,
                JSON.parse(JSON.stringify(err)),
              );
              reject(JSON.parse(JSON.stringify(err)));
            });
        });
      },
      update: ({ commit, dispatch, state }, params = null) => {
        commit(`UPDATING_${state.sName.toUpperCase()}`, params);
        return new Promise((resolve, reject) => {
          return state.services['update'](params)
            .then((res) => {
              commit(
                `UPDATING_${state.sName.toUpperCase()}_SUCCESS`,
                res.data ? (res.data.data ? res.data.data : res.data) : res,
              );
              resolve(res);
            })
            .catch((err) => {
              commit(
                `UPDATING_${state.sName.toUpperCase()}_ERROR`,
                JSON.parse(JSON.stringify(err)),
              );
              reject(JSON.parse(JSON.stringify(err)));
            });
        });
      },
      delete: ({ dispatch, commit, state }, params = null) => {
        commit(`DELETING_${state.sName.toUpperCase()}`, params);
        return new Promise((resolve, reject) => {
          return state.services['delete'](params)
            .then((res) => {
              commit(`DELETING_${state.sName.toUpperCase()}_SUCCESS`, params);
              dispatch('read');
              resolve(res);
            })
            .catch((err) => {
              commit(
                `DELETING_${state.sName.toUpperCase()}_ERROR`,
                JSON.parse(JSON.stringify(err)),
              );
              reject(JSON.parse(JSON.stringify(err)));
            });
        });
      },
    };
  }
}

export default StoreCRUD;
