import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  consumerApiCreate,
  consumerApiRead,
  consumerApiUpdate,
  consumerApiDelete,
} from '@/services/consumers';

const store = new StoreCRUD('consumer', {
  create: consumerApiCreate,
  read: consumerApiRead,
  update: consumerApiUpdate,
  delete: consumerApiDelete,
});

export const consumerStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
