import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { BASE_URL } from '@/consts';
import NProgress from 'nprogress';
import axios from 'axios';

// @ts-ignore
import checkRoles from '@/middleware/check-roles';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    },
    {
      path: '/gantt',
      name: 'gantt',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "gantt" */ '@/views/Gantt.vue'),
    },
    {
      path: '/customers',
      name: 'customers',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "consumers" */ '@/views/Customers.vue'),
    },
    {
      path: '/groups',
      name: 'groups',
      meta: {
        role: ['ROLE_SUPER_ADMIN'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "groups" */ '@/views/Groups.vue'),
    },
    {
      path: '/projects',
      name: 'projects',
      meta: {
        role: ['ROLE_SUPER_ADMIN'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "projects" */ '@/views/Projects.vue'),
      children: [
        {
          name: 'project',
          path: ':id',
          component: () =>
            import(/* webpackChunkName: "project" */ '@/views/Project.vue'),
        },
      ],
    },
    {
      path: '/tickets',
      name: 'tickets',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "tickets" */ '@/views/Tickets.vue'),
      children: [
        {
          name: 'ticket',
          path: ':id',
          meta: {
            role: ['ROLE_USER'],
            middleware: checkRoles,
          },
          component: () =>
            import(/* webpackChunkName: "ticket" */ '@/views/Ticket.vue'),
        },
      ],
    },
    {
      path: '/maintenance-contracts',
      name: 'maintenanceContracts',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(
          /* webpackChunkName: "MaintenanceContracts" */ '@/views/MaintenanceContracts.vue'
        ),
      children: [
        {
          name: 'maintenanceContract',
          path: ':id',
          meta: {
            role: ['ROLE_USER'],
            middleware: checkRoles,
          },
          component: () =>
            import(
              /* webpackChunkName: "MaintenanceContract" */ '@/views/MaintenanceContract.vue'
            ),
        },
      ],
    },
    {
      path: '/ticketCategories',
      name: 'ticketCategories',
      meta: {
        role: ['ROLE_SUPER_ADMIN'],
        middleware: checkRoles,
      },
      component: () =>
        import(
          /* webpackChunkName: "ticketCategories" */ '@/views/TicketCategories.vue'
        ),
    },
    {
      path: '/tasks',
      name: 'tasks',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "tasks" */ '@/views/Tasks.vue'),
    },
    // {
    //   name: 'sprint',
    //   path: '/sprint',
    //   component: () =>
    //     import(/* webpackChunkName: "sprint" */ '@/views/Sprint.vue'),
    //   meta: {
    //     role: 'ROLE_USER',
    //     middleware: checkRoles,
    //   },
    // },
    // {
    //   name: 'burnup',
    //   path: '/sprint/burnup',
    //   component: () =>
    //     import(/* webpackChunkName: "burnup" */ '@/views/Burnup.vue'),
    //   meta: {
    //     role: 'ROLE_USER',
    //     middleware: checkRoles,
    //   },
    // },
    // {
    //   name: 'burndown',
    //   path: '/sprint/burndown',
    //   component: () =>
    //     import(/* webpackChunkName: "burndown" */ '@/views/Burndown.vue'),
    //   meta: {
    //     role: 'ROLE_USER',
    //     middleware: checkRoles,
    //   },
    // },
    {
      path: '/kanbans',
      name: 'kanbans',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "kanbans" */ '@/views/Kanbans.vue'),
      children: [
        {
          name: 'kanban',
          path: ':id',
          component: () =>
            import(/* webpackChunkName: "kanban" */ '@/views/Kanban.vue'),
        },
      ],
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        role: ['ROLE_SUPER_ADMIN'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    },
    {
      path: '/scrum-board',
      name: 'scrumboard',
      component: () =>
        import(/* webpackChunkName: "scrum" */ '@/views/ScrumBoard.vue'),
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      children: [
        {
          path: ':id/backlog',
          name: 'backlog',
          component: () =>
            import(
              /* webpackChunkName: "backlog" */ '@/components/molecules/Backlog.vue'
            ),
          meta: {
            role: ['ROLE_USER'],
            middleware: checkRoles,
          },
        },
        {
          path: ':id/sprint',
          name: 'sprint',
          component: () =>
            import(
              /* webpackChunkName: "sprint" */ '@/components/molecules/Sprint.vue'
            ),
          meta: {
            role: ['ROLE_USER'],
            middleware: checkRoles,
          },
        },
        {
          path: ':id/graphs',
          name: 'graphs',
          component: () =>
            import(/* webpackChunkName: "sprint" */ '@/views/BurnGraphs.vue'),
          meta: {
            role: ['ROLE_USER'],
            middleware: checkRoles,
          },
          children: [
            {
              name: 'burnup',
              path: 'burnup',
              component: () => import('@/components/molecules/Burnup.vue'),
              meta: {
                role: ['ROLE_USER'],
                middleware: checkRoles,
              },
            },
            {
              name: 'burndown',
              path: 'burndown',
              component: () => import('@/components/molecules/Burndown.vue'),
              meta: {
                role: ['ROLE_USER'],
                middleware: checkRoles,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/absences',
      name: 'absences',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(/* webpackChunkName: "absences" */ '@/views/Absences.vue'),
    },
    {
      path: '/export/absences/:month',
      name: 'checkAbsencesByMonth',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
    },
    {
      path: '/export/teletravail/:month',
      name: 'checkTeleworkByMonth',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
    },
    {
      path: '/scrumTaskSearch',
      name: 'scrumTaskSearch',
      meta: {
        role: ['ROLE_USER'],
        middleware: checkRoles,
      },
      component: () =>
        import(
          /* webpackChunkName: "scrumTaskSearch" */ '@/views/ScrumTaskSearch.vue'
        ),
    },
  ],
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  const middleware = to.meta.middleware || null;
  const context = {
    to,
    from,
    next,
    store,
  };
  if (from.name !== to.name) {
    store
      .dispatch('setLoggedUser')
      .then(() => {
        return middleware(context, to.meta.role);
      })
      .then(() => {
        return store.dispatch('team/read');
      });
  }
  // if (!store.getters.isAuthenticated) {
  //   store.dispatch('setLoggedUser').then(() => {
  //     if (!to.meta.role) {
  //       return next();
  //     }
  //     return middleware(context, to.meta.role);
  //   });
  // } else {
  //   if (!to.meta.role) {
  //     return next();
  //   }
  //   return middleware(context, to.meta.role);
  // }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  // if (to.name) {
  // Start the route progress bar.
  //   NProgress.start();
  // }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

// const calculatePercentage = (loaded, total) => Math.floor(loaded * 1.0) / total;
// axios.defaults.onDownloadProgress = (e) => {
//   const percentage = calculatePercentage(e.loaded, e.total);
//   NProgress.set(percentage);
// };
// axios.interceptors.response.use((response) => {
//   NProgress.done(true);
//   return response;
// });

export default router;
