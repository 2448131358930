import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  projectApiCreate,
  projectApiRead,
  projectApiUpdate,
  projectApiDelete,
} from '@/services/projects';

const store = new StoreCRUD('project', {
  create: projectApiCreate,
  read: projectApiRead,
  update: projectApiUpdate,
  delete: projectApiDelete,
});

export const projectStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
    getProjectsByConsumer: (state) => (id) => {
      return state.projects.filter((project) => project.consumer === id);
    },
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
