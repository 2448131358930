import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
/* Users */

export const userApiCreate = (params = null) => {
  return graphqlApi(
    `mutation UserCreate {
      user_new(user: ${stringifyWithNoQuote(params)}) {
        id
        email
        username
        roles
        active
        avatar
        isVirtual
        projectManager
        active
        color
         onRhCalendar
         noteRh
         seeByRh
         workDays
         deletedAt
        team {
         id
        }
        manager {
          id
        }
        createdAt
        updatedAt
      }}
    `,
  );
  // return api.post(template('/users/')(params), params);
};

export const userApiRead = (params = null) => {
  return graphqlApi(
    `query UserRead {users {
         id
         email
         username
         roles
         active
         avatar
         isVirtual
         projectManager
         active
         color
         onRhCalendar
         noteRh
         seeByRh
         workDays
         deletedAt
         team {
          id
         }
        manager {
          id
        }
         createdAt
         updatedAt
         leftOn
      }}
    `,
  );
  // return api.get(template('/users/')(params), params);
};

export const userApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation UserUpdate {
      user_edit(user: ${stringifyWithNoQuote(params)}) {
        id
        email
        username
        roles
        active
        avatar
        isVirtual
        projectManager
        active
        color
        deletedAt
        onRhCalendar
        noteRh
        seeByRh
        workDays
        team {
         id
        }
        manager {
          id
        }
        createdAt
        updatedAt
        leftOn
      }}
    `,
  );
  // return api.put(template('/users/${id}')(params), params);
};

export const userApiDelete = (params = null) => {
  return graphqlApi(
    `mutation UserDelete {
      user_delete(user: {id: ${stringifyWithNoQuote(params)}}) {
        id
      }}
    `,
  );
  // return api.delete(template('/users/${id}')({ id: params }), params);
};
