import {
  scrumBoardApiCreate,
  scrumBoardApiUpdate,
  scrumBoardApiReadListView,
  scrumBoardApiReadById,
  scrumBoardApiReadByIdGetUsers,
} from '@/services/scrumBoard';

import {
  scrumSprintApiUpdate,
  scrumSprintApiCreate,
  scrumSprintApiClose,
} from '@/services/scrumSprint';

import {
  scrumTaskApiCreate,
  scrumTaskApiUpdate,
  scrumTaskApiReadById,
  scrumTaskApiDelete,
  scrumTaskApiMove,
} from '@/services/scrumTasks';
import { orderBy } from 'lodash';
import Fuse from 'fuse.js';

export const scrumBoardStore = {
  namespaced: true,
  state: {
    taskLastCreated: null,
    scrumBoards: [],
    currentScrumBoard: null,
    tasks: [],
    tasksProcessing: [],
    scrumSprintLoading: false,
    userSearch: [],
    inputSearch: '',
    tagSearch: [],
    orderScrumTasks: 'priority',
  },
  getters: {
    // ScrumBoards
    scrumSprintLoading(state) {
      return state.scrumSprintLoading;
    },
    scrumBoards(state) {
      return state.scrumBoards;
    },
    currentScrumBoard(state) {
      return state.currentScrumBoard;
    },
    tasksProcessing(state) {
      return state.tasksProcessing;
    },
    tasks(state) {
      let tasks;

      if (state.tasks) {
        tasks = state.tasks;

        if (state.inputSearch) {
          const fuse = new Fuse(tasks, {
            keys: ['name', 'consumer.name', 'project.name'],
            threshold: 0.2,
          });
          tasks = fuse.search(state.inputSearch);
        }
        if (state.tagSearch.length > 0) {
          tasks = tasks.filter(
            (task) =>
              task.name
                .split(' ')
                .some((word) => state.tagSearch.includes(word)) ||
              (state.tagSearch.includes('#ticket') && task.ticket),
          );
        }
        if (state.userSearch.length > 0) {
          tasks = tasks.filter((task) => {
            return task.assignee
              .map((item) => item.id)
              .some((assignee) => state.userSearch.includes(assignee));
          });
        }

        let orders = ['consumer.name', 'project.name', 'name'];
        let ordersType: any = ['asc', 'asc', 'asc'];
        if (state.orderScrumTasks !== 'name') {
          orders.unshift(state.orderScrumTasks);
          ordersType.unshift('desc');
        }
        return orderBy(tasks, orders, ordersType);
      }
      return [];
    },
    taskTags(state) {
      let tags = [];
      state.tasks.forEach((task) => {
        if (task.ticket && !tags.includes('#ticket')) {
          tags = [...tags, '#ticket'];
        }
        let taskWords = task.name.split(' ');
        taskWords
          .filter((word) => word.charAt(0) === '#' && word.length > 1)
          .forEach((tag) => {
            if (!tags.includes(tag)) {
              tags = [...tags, tag];
            }
          });
      });
      return tags;
    },
    taskTagSearch(state) {
      return state.tagSearch;
    },
    // ScrumTasks
    sprintTasks(state, getter) {
      return getter['tasks'].filter((task) => !task.isBacklog);
    },
    backlogTasks(state, getter) {
      return getter['tasks'].filter((task) => task.isBacklog);
    },
    // Global Methods
    getScrumTaskById: (state) => (id) => {
      return state.tasks.find((task) => task.id === id);
    },
    taskLastCreated: (state) => {
      return state.taskLastCreated;
    },
    getWorkLoadPerAssigneeItems(state, getter) {
      let users = state.currentScrumBoard.users.filter((user) => {
        if (state.userSearch.length) {
          return state.userSearch.includes(user.id);
        }
        return user;
      });
      const array = users.map((user) => {
        const userTasks = getter['sprintTasks'].filter((task) => {
          return task.assignee.map((assignee) => assignee.id).includes(user.id);
        });
        return {
          responsible: user.username,
          tasks: userTasks.length,
          storyPoint: userTasks.reduce(
            (total, task) => total + task.storyPoints,
            0,
          ),
          elapsedTime: userTasks.reduce(
            (total, task) => total + task.elapsedTime,
            0,
          ),
        };
      });
      return array;
    },
    getScrumBoardTaskPoints(state, getter) {
      let tasks = getter['tasks'].filter((task) => !task.isBacklog);
      return tasks.reduce(
        (total, task) => total + task.storyPoints * task.assignee.length,
        0,
      );
    },
    getScrumBoardTaskElapsedTime(state, getter) {
      let tasks = getter['tasks'].filter((task) => !task.isBacklog);
      return tasks.reduce(
        (total, task) => total + task.elapsedTime * task.assignee.length,
        0,
      );
    },
    getNumberOfPeerProgrammingTasks(state, getter) {
      let tasks = getter['tasks'].filter((task) => !task.isBacklog);
      const peerProgrammingTasks = tasks.filter(
        (task) => task.assignee.length > 1,
      );
      return peerProgrammingTasks.length;
    },
  },
  mutations: {
    // -------------------- OrderScrumTasks
    setOrderScrumTasks(state, valueOrder) {
      state.orderScrumTasks = valueOrder;
    },
    // -------------------- ScrumBoards
    createScumBoard(state, payload) {
      state.scrumBoards = [...state.scrumBoards, payload];
    },
    updateScumBoard(state, payload) {
      state.scrumBoards = state.scrumBoards.filter(
        (board) => board.id !== payload.id,
      );
      state.scrumBoards = [...state.scrumBoards, payload];
    },
    setScrumBoards(state, payload) {
      state.scrumBoards = payload;
    },
    setCurrentScrumBoard(state, payload) {
      state.currentScrumBoard = payload;
    },
    // --------------------- ScrumSprints
    createScrumSprint(state, payload) {
      state.currentScrumBoard.currentSprint = payload;
    },
    updateScrumSprint(state, payload) {
      state.currentScrumBoard.currentSprint = payload;
    },
    currentScrumBoardActionStart(state) {
      state.scrumSprintLoading = true;
    },
    currentScrumBoardActionStop(state) {
      state.scrumSprintLoading = false;
    },
    //  -------------------- ScrumTasks
    setScrumTasks(state) {
      state.tasks = [];
      state.tasks = [...state.currentScrumBoard.tasksInBacklog];
      if (
        state.currentScrumBoard.currentSprint &&
        state.currentScrumBoard.currentSprint.tasks
      ) {
        state.tasks = [
          ...state.tasks,
          ...state.currentScrumBoard.currentSprint.tasks,
        ];
      }
    },
    setTaskProcessing(state, ids) {
      state.tasksProcessing = ids;
    },
    clearTaskProcessing(state) {
      state.tasksProcessing = [];
    },
    createScrumTask(state, payload) {
      state.tasks = [...state.tasks, payload];
      state.taskLastCreated = payload.id;
    },
    updateScrumTask(state, payload) {
      state.tasks = state.tasks.filter((task) => task.id !== payload.id);
      state.tasks = [...state.tasks, payload];
    },
    deleteScrumTask(state, id) {
      state.tasks = state.tasks.filter((task) => task.id !== id);
    },
    // Update several tasks
    updateScrumTasks: (state, payload) => {
      state.tasks = state.tasks.filter(
        (task) => !payload.map((t) => t.id).includes(task.id),
      );
      state.tasks = [...state.tasks, ...payload];
    },
    deleteScrumTasks: (state, payload) => {
      state.tasks = state.tasks.filter(
        (task) => !payload.map((t) => t.id).includes(task.id),
      );
    },
    setTaskLastCreated: (state, value) => {
      state.taskLastCreated = value;
    },
    // ------------------ Global mutations
    setUserSearch(state, value) {
      state.userSearch = value;
    },
    setTagSearch(state, value) {
      if (state.tagSearch.includes(value)) {
        state.tagSearch = state.tagSearch.filter((tag) => tag !== value);
      } else {
        state.tagSearch = [...state.tagSearch, value];
      }
    },
    setInputSearch(state, value) {
      state.inputSearch = value;
    },
  },
  actions: {
    scrumBoardCreate({ commit }, params) {
      return new Promise((resolve, reject) => {
        return scrumBoardApiCreate(params)
          .then(({ scrum_board_new }) => {
            resolve(scrum_board_new);
            commit('createScumBoard', scrum_board_new);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    scrumBoardUpdate({ commit }, params) {
      return new Promise((resolve, reject) => {
        return scrumBoardApiUpdate(params)
          .then(({ scrum_board_edit }) => {
            resolve(scrum_board_edit);
            commit('updateScumBoard', scrum_board_edit);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //  --------------------- ScrumBoard
    scrumBoardsListRead({ commit }) {
      return new Promise((resolve, reject) => {
        return scrumBoardApiReadListView()
          .then(({ scrumBoards }) => {
            resolve(scrumBoards);
            commit('setScrumBoards', scrumBoards);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    currentScrumBoardRead({ commit }, id) {
      return new Promise((resolve, reject) => {
        return scrumBoardApiReadById(id)
          .then(({ scrumBoard }) => {
            resolve(scrumBoard);
            commit('setCurrentScrumBoard', scrumBoard);
            commit('currentScrumBoardActionStop');
            commit('setScrumTasks');
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    scrumBoardUsers({ state }) {
      return new Promise((resolve, reject) => {
        return scrumBoardApiReadByIdGetUsers(
          state.currentScrumBoard ? state.currentScrumBoard.id : null,
        )
          .then(({ scrumBoard }) => {
            resolve(scrumBoard);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //  --------------------- ScrumSprint
    scrumSprintCreate({ commit, dispatch, state }, params) {
      commit('currentScrumBoardActionStart');
      return new Promise((resolve, reject) => {
        return scrumSprintApiCreate(params)
          .then(({ sprint_new }) => {
            // commit('createScrumSprint', sprint_new);
            dispatch('currentScrumBoardRead', state.currentScrumBoard.id);
            resolve(sprint_new);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // Start scrumSprint
    scrumSprintUpdate({ commit, dispatch, state }, params) {
      commit('currentScrumBoardActionStart');
      return new Promise((resolve, reject) => {
        return scrumSprintApiUpdate(params)
          .then(({ sprint_edit }) => {
            // commit('updateScrumSprint', sprint_edit);
            dispatch('currentScrumBoardRead', state.currentScrumBoard.id);
            // commit('currentScrumBoardActionStop');
            resolve(sprint_edit);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    scrumSprintClose({ dispatch, state, commit }, params) {
      commit('currentScrumBoardActionStart');
      return new Promise((resolve, reject) => {
        return scrumSprintApiClose(params)
          .then(({ sprint_edit }) => {
            resolve(sprint_edit);
            dispatch('currentScrumBoardRead', state.currentScrumBoard.id);
            // commit('currentScrumBoardActionStop');
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //  --------------------- ScrumTasks
    scrumTaskRead({ commit }, params) {
      return new Promise((resolve, reject) => {
        return scrumTaskApiReadById(params)
          .then(({ scrumTask }) => {
            resolve(scrumTask);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    scrumTaskCreate({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        return scrumTaskApiCreate(id)
          .then(({ scrum_task_new }) => {
            resolve(scrum_task_new);
            if (scrum_task_new.scrumBoard.id === state.currentScrumBoard.id) {
              commit('createScrumTask', scrum_task_new);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    scrumTaskUpdate({ commit }, params) {
      commit('setTaskProcessing', [params.id]);
      return new Promise((resolve, reject) => {
        return scrumTaskApiUpdate(params)
          .then(({ scrum_task_edit }) => {
            resolve(scrum_task_edit);
            commit('updateScrumTask', scrum_task_edit);
            commit('clearTaskProcessing');
          })
          .catch((err) => {
            reject(err);
            commit('clearTaskProcessing');
          });
      });
    },
    scrumTaskDelete({ commit }, params) {
      commit('setTaskProcessing', [params.id]);
      return new Promise((resolve, reject) => {
        return scrumTaskApiDelete(params)
          .then(({ scrum_task_delete }) => {
            resolve(scrum_task_delete);
            commit('deleteScrumTask', params.id);
            commit('clearTaskProcessing');
          })
          .catch((err) => {
            reject(err);
            commit('clearTaskProcessing');
          });
      });
    },
    // Move multiple tasks to backlog or sprint
    scrumTasksMove({ commit }, params) {
      commit(
        'setTaskProcessing',
        params.tasks.map((item) => item.id),
      );
      return new Promise((resolve, reject) => {
        return scrumTaskApiMove(params)
          .then(({ scrum_task_move_sprint_backlog }) => {
            resolve(scrum_task_move_sprint_backlog);
            commit('updateScrumTasks', scrum_task_move_sprint_backlog);
            commit('clearTaskProcessing');
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
