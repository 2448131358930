import { api } from '@/consts';
import { template } from 'lodash';

/* Addresses */

export const addressApiCreate = (params = null) => {
  return api.post(
    template('/consumers/${consumerId}/addresses')(params),
    params,
  );
};

export const addressApiRead = (params = null) => {
  return api.get(
    template('/consumers/${consumerId}/addresses')(params),
    params,
  );
};

export const addressApiUpdate = (params = null) => {
  return api.put(
    template('/consumers/${consumerId}/addresses/${id}')(params),
    params,
  );
};

export const addressApiDelete = (params = null) => {
  return api.delete(
    template('/consumers/${consumerId}/addresses/${id}')({ id: params }),
    params,
  );
};
