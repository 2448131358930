import axios from 'axios';
import { GraphQLClient } from 'graphql-request';

const BASE_URL =
  process.env.NODE_ENV === 'test' ? 'http://55.55.55.55/admin' : '/admin';

const client = new GraphQLClient(`${BASE_URL}/graphql/`, {
  headers: {
    'Content-Type': 'application/json',
  },
});

const MERCURE_SCRUM_NOTIFICATION_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/.well-known/mercure?topic='
    : 'https://mercure.namkin.fr/.well-known/mercure?topic=';

const api = axios.create({
  baseURL: `${BASE_URL}/api`,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const graphqlApi = async (query: string, variables?: any) => {
  try {
    const result = await client.request(query, variables);
    return result;
  } catch (e) {
    if (e['response'].statusCode && e['response'].statusCode === 401) {
      window.location.reload();
    }
  }
};

const graphApiUpload = (data) => {
  return axios
    .post(`${BASE_URL}/graphql/`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .catch(() => {
      window.location.reload();
    });
};

const getTicketsUploadDir = (filename) => {
  return `/uploads/tickets/${filename}`;
};

export {
  BASE_URL,
  MERCURE_SCRUM_NOTIFICATION_URL,
  api,
  graphqlApi,
  graphApiUpload,
  getTicketsUploadDir,
};
