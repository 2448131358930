import { graphqlApi } from '@/consts';

/* Projects */

export const projectApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      project_new(project: ${params}) {
        id
        name
        consumer
        manager {
          id
          username
        }
        category
        createdAt
        updatedAt
      }}
    `,
  );
  // return api.post(template('/projects/')(params), params);
};

export const projectApiRead = (params = null) => {
  return graphqlApi(
    `{projects {
         id
         name
         consumer
         manager {
          id
         }
         category
         createdAt
         updatedAt
      }}
    `,
  );
  // return api.get(template('/projects/')(params), params);
};

export const projectApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      project_edit(project: ${params}) {
        id
        name
        consumer
        manager {
          id
          username
        }
        category
        createdAt
        updatedAt
      }}
    `,
  );
  // return api.put(template('/projects/${id}')(params), params);
};

export const projectApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      project_delete(project: ${params}) {
        id
        name
        consumer
        manager {
          id
          username
        }
        category
        createdAt
        updatedAt
      }}
    `,
  );
  // return api.delete(template('/projects/${id}')({ id: params }), params);
};
