import {
  ticketCommentApiCreate,
  ticketCommentApiRead,
  ticketCommentApiUpdate,
  ticketCommentApiDelete,
} from '@/services/ticketComments';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('ticketComment', {
  create: ticketCommentApiCreate,
  read: ticketCommentApiRead,
  update: ticketCommentApiUpdate,
  delete: ticketCommentApiDelete,
});

export const ticketCommentStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
