import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

/* Roles */

export const roleApiRead = (params = null) => {
  return graphqlApi(
    `{
      roles {
        name
      }
    }`,
  );
};
