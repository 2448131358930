<template>
  <div
    class="navigation pr-px-2 pr-py-8 pr-justify-between pr-flex-col pr-flex"
    v-if="$store.getters.loggedUser"
  >
    <!-- Vue Router in action -->
    <div>
      <el-popover
        v-for="(link, index) in links"
        v-if="
          link.access.find((accessRole) =>
            $store.getters.roles.includes(accessRole),
          ) && (link.hasOwnProperty('condition') ? link.condition : true)
        "
        :content="link.name"
        :key="index"
        trigger="hover"
        placement="right"
      >
        <router-link
          slot="reference"
          :to="link.to"
          class="navigation__link pr-mb-4 pr-inline-flex pr-w-full pr-rounded-lg pr-shadow-lg"
          :class="{ 'navigation__link--active': $route.name === link.to.name }"
        >
          <!-- Tailwind classes in action -->
          <div class="pr-w-full pr-ratio-1/1">
            <div
              class="navigation__icons pr-mb pr-rounded-lg pr-absolute pr-inline-flex pr-pin pr-justify-center pr-items-center"
            >
              <!-- Ionicons in action-->
              <i
                :class="[link.icon]"
                class="icon pr-text-xl"
                v-bind="link.attr"
              ></i>
            </div>
          </div>
        </router-link>
      </el-popover>
    </div>
    <div v-if="$store.getters.loggedUser">
      <el-popover
        trigger="hover"
        placement="right"
        :content="`Logged as ${$store.getters.loggedUser.username}`"
      >
        <div
          class="navigation__user"
          :style="`background-image: url(${$store.getters.loggedUser.avatar})`"
          slot="reference"
        ></div>
      </el-popover>
      <br />
      <el-popover trigger="hover" placement="right" content="Logout">
        <a
          href="/logout"
          slot="reference"
          class="navigation__link pr-mb-4 pr-inline-flex pr-w-full pr-rounded-lg pr-shadow-lg"
        >
          <div class="pr-w-full pr-ratio-1/1">
            <div
              class="navigation__icons pr-mb pr-rounded-lg pr-absolute pr-inline-flex pr-pin pr-justify-center pr-items-center"
            >
              <i class="ion-md-power icon pr-text-xl"></i>
            </div>
          </div>
        </a>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import slugify from '@/plugins/slugify';
export default {
  name: 'Navigation',
  computed: {
    isInDevTeam() {
      return slugify(this.$store.getters.loggedUser.team.name) === 'pole-dev';
    },
    links() {
      return [
        {
          to: { name: 'home' },
          name: 'Homepage',
          icon: 'ion-md-home',
          access: ['ROLE_USER'],
        },
        {
          to: { name: 'gantt' },
          name: 'Gantt',
          icon: 'ion-md-calendar',
          access: ['ROLE_USER'],
        },
        // {
        //   to: { name: 'kanbans' },
        //   name: 'Kanbans',
        //   icon: 'ion-md-list-box',
        //   access: 'ROLE_USER',
        //   attr: {
        //     style: 'transform: rotate(90deg)',
        //   },
        // },
        {
          to: { name: 'customers' },
          name: 'Customers',
          icon: 'ion-md-people',
          access: ['ROLE_USER'],
        },
        // {
        //     to: {name: 'projects'},
        //     name: 'Projects',
        //     icon: 'ion-md-filing',
        //     access: 'ROLE_USER',
        // },
        // {
        //     to: {name: 'tasks'},
        //     name: 'Tasks',
        //     icon: 'ion-md-clipboard',
        //     access: 'ROLE_USER',
        // },
        {
          to: { name: 'tickets' },
          name: 'Tickets',
          icon: 'ion-md-bookmark',
          access: ['ROLE_USER'],
        },
        {
          to: { name: 'maintenanceContracts' },
          name: 'Maintenance Contracts',
          icon: 'ion-md-construct',
          access: ['ROLE_USER'],
        },
        // {
        //   to: { name: 'ticketCategories' },
        //   name: 'Ticket Categories',
        //   icon: 'ion-md-bookmarks',
        //   access: ['ROLE_SUPER_ADMIN'],
        // },
        {
          to: { name: 'users' },
          name: 'Users',
          icon: 'ion-md-contact',
          access: ['ROLE_SUPER_ADMIN'],
        },
        {
          to: { name: 'absences' },
          name: 'Absences',
          icon: 'ion-md-walk',
          access: ['ROLE_USER'],
        },
        {
          to: { name: 'groups' },
          name: 'Groups',
          icon: 'ion-md-people',
          access: ['ROLE_SUPER_ADMIN'],
        },
        {
          to: { name: 'scrumboard' },
          name: 'ScrumBoard',
          icon: 'ion-md-rocket',
          access: ['ROLE_USER'],
        },
        {
          to: { name: 'scrumTaskSearch' },
          name: 'ScrumTask search tool',
          icon: 'ion-md-search',
          access: ['ROLE_SUPER_ADMIN'],
        },
      ];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@/styles/functions/all';

.navigation {
  height: 100%;
  background-color: #f6f5fb;

  .navigation__icons {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .navigation__link {
    background-color: white;
    color: theme(colors, grey-darker);

    &--active {
      color: theme(colors, primary);
    }
  }
  .navigation__user {
    margin: auto;
    border-radius: 999px;
    height: 35px;
    width: 35px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
<style lang="scss">
.el-popover {
  min-width: inherit !important;
}
</style>
