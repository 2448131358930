import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

/* Ticket Comments */

export const ticketCommentApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_comment_new(ticket_comment: ${stringifyWithNoQuote(params)}) {
        id
        author {
          id
          avatar
          username
        }
        ticket {
          id
        }
        content
        createdAt
        updatedAt
      }
    }`,
  );
};

export const ticketCommentApiRead = (params = null) => {
  return graphqlApi(
    `{
      ticketComments {
        id
        author {
          id
          avatar
          username
        }
        ticket {
          id
        }
        content
        createdAt
        updatedAt
      }
    }`,
  );
};

export const ticketCommentApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_comment_edit(ticket_comment: ${stringifyWithNoQuote(params)}) {
        id
        author {
          id
          avatar
          username
        }
        ticket {
          id
        }
        content
        createdAt
        updatedAt
      }
    }`,
  );
};

export const ticketCommentApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_comment_delete(ticket_comment: ${stringifyWithNoQuote(params)}) {
        id
      }
    }`,
  );
};
