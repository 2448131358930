import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { consumerStore } from '@/store/consumer.store';
import { projectStore } from '@/store/project.store';
import { addressStore } from '@/store/address.store';
import { taskStore } from '@/store/task.store';
import { ticketStore } from '@/store/ticket.store';
import { ticketCategoryStore } from '@/store/ticketCategory.store';
import { ticketCommentStore } from '@/store/ticketComment.store';
import { ticketFileStore } from '@/store/ticketFile.store';
import { userStore } from '@/store/user.store';
import { absenceStore } from '@/store/absence.store';
import { kanbanStore } from '@/store/kanban.store';
import { columnStore } from '@/store/columns.store';
import { kanbanTaskStore } from '@/store/kanbanTask.store';
import { groupStore } from '@/store/group.store';
import { scrumTaskStore } from '@/store/scrumTask.store';
import { teamStore } from '@/store/teams.store';
import { maintenanceContractStore } from '@/store/maintenanceContracts.store';
import { maintenanceContractCounterStore } from '@/store/maintenanceContractCounters.store';
import { graphStore } from '@/store/graph.store';
import { scrumBoardStore } from '@/store/scrumBoard.store';
import { roleStore } from '@/store/roles.store';
import { restaurantTicketsHistoryStore } from '@/store/restaurantTicketsHistory.store';
import { payHistoryStore } from '@/store/payHistory.store';
import { checkPayHistoryStore } from '@/store/checkPayHistory.store';
import axios from 'axios';

export const state = {
  loggedUser: null,
  isAuthenticated: false,
  isLoading: false,
};

export const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  roles(state) {
    return state.loggedUser ? state.loggedUser.roles : [];
  },
  loggedUser(state) {
    return state.loggedUser;
  },
};

export const mutations = {
  ['FETCH_LOGGED_USER'](state) {
    state.isLoading = true;
  },
  ['FETCH_LOGGED_USER_SUCCESS'](state, data) {
    state.loggedUser = data;
    state.isAuthenticated = true;
    state.isLoading = false;
  },
};

export const actions = {
  setLoggedUser({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit('FETCH_LOGGED_USER');
      axios
        .get('/admin/me')
        .then((res) => {
          commit('FETCH_LOGGED_USER_SUCCESS', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          location.replace(`${(window as any).location.origin}/login`);
        });
    });
  },
};

export const modules = {
  consumer: consumerStore,
  project: projectStore,
  address: addressStore,
  task: taskStore,
  ticket: ticketStore,
  ticketCategory: ticketCategoryStore,
  ticketComment: ticketCommentStore,
  ticketFile: ticketFileStore,
  user: userStore,
  absence: absenceStore,
  kanban: kanbanStore,
  columns: columnStore,
  kanbanTask: kanbanTaskStore,
  group: groupStore,
  scrumTask: scrumTaskStore,
  team: teamStore,
  graph: graphStore,
  maintenanceContract: maintenanceContractStore,
  maintenanceContractCounter: maintenanceContractCounterStore,
  scrumBoard: scrumBoardStore,
  role: roleStore,
  restaurantTicketsHistory: restaurantTicketsHistoryStore,
  payHistory: payHistoryStore,
  checkPayHistory: checkPayHistoryStore,
};

export default new Vuex.Store({
  strict: true,
  state,
  getters,
  mutations,
  actions,
  modules,
});
