import { api } from '@/consts';
import { forEach, cloneDeep } from 'lodash';
import {
  kanbanColumnApiCreate,
  kanbanColumnApiRead,
  kanbanColumnApiUpdate,
  kanbanColumnApiDelete,
} from '@/services/kanbans';
import StoreCRUD from '@/factory/StoreCRUD.ts';
const store = new StoreCRUD('column', {
  create: kanbanColumnApiCreate,
  read: kanbanColumnApiRead,
  update: kanbanColumnApiUpdate,
  delete: kanbanColumnApiDelete,
});

export const columnStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
    ['MOVE_COLUMN'](state, datas) {
      const columnsClone = cloneDeep(state.columns);
      datas.map((data, index) => (data.position = index + 1));
      let dataIDs = [];
      forEach(datas, (data) => {
        dataIDs = [...dataIDs, data.id];
      });
      forEach(columnsClone, (col) => {
        forEach(datas, (data) => {
          if (data.id === col.id) {
            col.position = data.position;
          }
        });
      });
      state.columns = columnsClone;
    },
  },
  actions: {
    ...store.actions,
    updatePosition({ dispatch, commit }, data) {
      commit('MOVE_COLUMN', data);
    },
    detach({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        return api
          .delete(`kanbans/${data.kanbanId}/detach/${data.columnId}`)
          .then((res) => {
            dispatch('read').then(() => {
              // commit('DELETING_TASK_SUCCESS', taskId);
              resolve(res.data);
            });
          })
          .catch((err) => {
            commit('CREATING_COLUMN_ERROR', err);
            reject();
          });
      });
    },
    move({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        // /{kanban_id}/edit/{id}
        return api
          .put(`kanbans/${data.kanbanId}/move/${data.columnId}`, {
            position: data.position,
          })
          .then((res) => {
            dispatch('read').then(() => {
              // commit('DELETING_TASK_SUCCESS', taskId);
              resolve(res.data);
            });
          })
          .catch((err) => {
            commit('CREATING_COLUMN_ERROR', err);
            reject();
          });
      });
    },
  },
};
