import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  payHistoryApiCreate,
  payHistoryApiRead,
  payHistoryApiUpdate,
  payHistoryApiDelete,
} from '@/services/payHistory';

const store = new StoreCRUD('payHistory', {
  create: payHistoryApiCreate,
  read: payHistoryApiRead,
  update: payHistoryApiUpdate,
  delete: payHistoryApiDelete,
});

export const payHistoryStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
