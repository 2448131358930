import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

/* Maintenance Contracts */

export const maintenanceContractApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      maintenance_contract_new(maintenance_contract: ${stringifyWithNoQuote(
        params,
      )}) {
    id
    client {
      id
      name
    }
    start_date
    end_date
    hourly_volume
    hourly_rate
    status
    created_at
    updated_at
  }
    }`,
  );
};

export const maintenanceContractApiRead = (params = null) => {
  return graphqlApi(
    `{
  maintenanceContracts {
    id
    client {
      id
      name
    }
    start_date
    end_date
    hourly_volume
    hourly_rate
    status
    created_at
    updated_at
  }
}`,
  );
};

export const maintenanceContractReadById = (id = null) => {
  return graphqlApi(
    `{maintenanceContract(id: ${id}) {
        id
        client {
          id
          name
        }
        start_date
        end_date
        hourly_volume
        hourly_rate
        status
        created_at
        updated_at
      }}`,
  );
};

export const maintenanceContractApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      maintenance_contract_edit(maintenance_contract: ${stringifyWithNoQuote(
        params,
      )}) {
    id
    client {
      id
      name
    }
    start_date
    end_date
    hourly_volume
    hourly_rate
    status
    created_at
    updated_at
  }
    }`,
  );
};

export const maintenanceContractApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      maintenance_contract_delete(maintenance_contract: ${stringifyWithNoQuote(
        params,
      )}) {
        id
      }
    }`,
  );
};
