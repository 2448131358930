import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
/* Consumers */

export const consumerApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      consumer_new(consumer: ${stringifyWithNoQuote(params)}) {
        id
        name
        description
        logo
        status
        addresses {
          id
          address1
          address2
          city
          contactName
          companyName
          zip
          tel
          email
          isHeadOffice
        }
        referent {
          id
        }
        createdAt
        updatedAt
      }}
    `,
  );
  // return api.post(template('/consumers/')(params), params);
};

export const consumerApiRead = (params = null) => {
  return graphqlApi(
    `{consumers {
         id
         name
         description
         logo
         status
         addresses {
          id
          address1
          address2
          city
          contactName
          companyName
          zip
          tel
          email
          isHeadOffice
         }
         referent {
          id
         }
         createdAt
         updatedAt
      }}
    `,
  );
  // return api.get(template('/consumers/')(params), params);
};

export const consumerApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      consumer_edit(consumer: ${stringifyWithNoQuote(params)}) {
        id
        name
        description
        logo
        status
        addresses {
          id
          address1
          address2
          city
          contactName
          companyName
          zip
          tel
          email
          isHeadOffice
        }
        referent {
          id
        }
        createdAt
        updatedAt
      }}
    `,
  );
  // return api.put(template('/consumers/${id}')(params), params);
};

export const consumerApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      consumer_delete(consumer: {id: ${stringifyWithNoQuote(params)}}) {
        id
      }}
    `,
  );
  // return api.delete(template('/consumers/${id}')({ id: params }), params);
};
