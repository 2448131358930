import StoreCRUD from '@/factory/StoreCRUD';
import { scrumTaskStatusApiRead } from '@/services/scrumTasks';
//
const store = new StoreCRUD('scrumTask', {});
//
export const scrumTaskStore = {
  namespaced: true,
  state: {
    ...store.state,
    statuses: [],
  },
  getters: {
    getStatuses(state) {
      return state.statuses;
    },
  },
  mutations: {
    ['READ_STATUS']: (state) => {
      state.isLoading = true;
    },
    ['READ_STATUS_SUCCESS']: (state, data) => {
      state.isLoading = false;
      state.statuses = data;
    },
    ['READ_STATUS_ERROR']: (state) => {
      state.isLoading = false;
    },
  },
  actions: {
    statusesRead({ commit }) {
      commit('READ_STATUS');
      return new Promise((resolve, reject) => {
        return scrumTaskStatusApiRead()
          .then(({ scrumTasksStatus }) => {
            commit('READ_STATUS_SUCCESS', scrumTasksStatus);
            resolve(scrumTasksStatus);
          })
          .catch((err) => {
            commit('READ_STATUS_ERROR');
            reject(err);
          });
      });
    },
  },
};
