import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  teamApiCreate,
  teamApiRead,
  teamApiUpdate,
  teamApiDelete,
} from '@/services/teams';

const store = new StoreCRUD('team', {
  create: teamApiCreate,
  read: teamApiRead,
  update: teamApiUpdate,
  delete: teamApiDelete,
});
// export default {
export const groupStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
