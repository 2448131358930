import { graphqlApi, graphApiUpload } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
/* RestaurantTicketsHistory */

export const restaurantTicketsHistoryApiCreate = (params = null) => {
  return graphqlApi(
    `
      mutation {
        restaurant_tickets_history_new(restaurant_tickets_history: ${stringifyWithNoQuote(
          params,
        )}) {
          id
          user {
            id
          }
          createdAt
          updatedAt
          workdays
          periodHistory
        }}
    `,
  );
};

export const restaurantTicketsHistoryApiRead = (params = null) => {
  return graphqlApi(
    `{restaurantTicketsHistories(user: ${
      params ? params.user : null
    }, period: "${params ? params.period : null}") {
          id
          workdays
          createdAt
          user {
            id
            username
          }
        }}
    `,
  );
};

export const restaurantTicketsHistoryApiUpdate = (params = null) => {
  return graphqlApi(
    `
      mutation {
        restaurant_tickets_history_edit(restaurantTicketsHistory: ${stringifyWithNoQuote(
          params,
        )}) {
          id
          user {
            id
          }
          createdAt
          updatedAt
          workdays
          periodHistory
        }}
    `,
  );
};

export const restaurantTicketsHistoryApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
        restaurant_tickets_history_delete(restaurantTicketsHistory: {id: ${stringifyWithNoQuote(
          params,
        )}}) {
          id
        }}
    `,
  );
};
