import { api } from '@/consts';
import { template } from 'lodash';

/* Kanbans */

export const kanbanApiCreate = (params = null) => {
  return api.post(template('/kanbans/add')(params), params);
};

export const kanbanApiRead = (params = null) => {
  return api.get(template('/kanbans/')(params), params);
};

export const kanbanApiUpdate = (params = null) => {
  return api.put(template('/kanbans/${id}')(params), params);
};

export const kanbanApiDelete = (params = null) => {
  return api.delete(template('/kanbans/${id}')(params), params);
};

/* Kanban Tasks */

export const kanbanTaskApiCreate = (params = null) => {
  return api.post(template('/kanbans/tasks/')(params), params);
};

export const kanbanTaskApiRead = (params = null) => {
  return api.get(template('/kanbans/tasks')(params), params);
};

export const kanbanTaskApiUpdate = (params = null) => {
  return api.put(template('/kanbans/tasks/${id}')(params), params);
};

export const kanbanTaskApiDelete = (params = null) => {
  return api.delete(template('/kanbans/tasks/${id}')(params), params);
};

export const kanbanTaskApiMove = (params = null) => {
  return api.put(
    template('/kanbans/tasks/update-position/${id}')(params),
    params,
  );
};

/* Kanban Columns */

export const kanbanColumnApiCreate = (params = null) => {
  return api.post(template('/kanbans/columns/${kanbanId}/add')(params), params);
};

export const kanbanColumnApiRead = (params = null) => {
  return api.get(template('/kanbans/columnKanban/')(params), params);
};

export const kanbanColumnApiUpdate = (params = null) => {
  return api.put(
    template('/kanbans/columns/${kanbanId}/edit/${id}')(params),
    params,
  );
};

export const kanbanColumnApiDelete = (params = null) => {
  return api.delete(template('/kanbans/columns/${id}')(params), params);
};

export const kanbanColumnApiMove = (params = null) => {
  return api.put(template('kanbans/${kanbanId}/move/${id}')(params), params);
};
