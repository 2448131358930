import { roleApiRead } from '@/services/roles';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('role', {
  read: roleApiRead,
});

export const roleStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
