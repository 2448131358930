import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  userApiCreate,
  userApiRead,
  userApiUpdate,
  userApiDelete,
} from '@/services/users';
const store = new StoreCRUD('user', {
  create: userApiCreate,
  read: userApiRead,
  update: userApiUpdate,
  delete: userApiDelete,
});

export const userStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
    getUsersByIds: (state) => (ids) => {
      return state.users.filter((user) => ids.includes(user.id));
    },
    getActiveUsersByIds: (state) => (ids) => {
      return state.users.filter(
        (user) => user.active === true && ids.includes(user.id),
      );
    },
    sprintUsers(state, getters, rootStates, rootGetters) {
      const devTeam = rootStates.team.teams.find(
        // TODO: change name propety to slug when it has been implemented
        (team) => team.name === 'Pôle dev',
      );
      return rootGetters['user/users'].filter((user) => {
        if (devTeam) {
          return user.team.id === devTeam.id;
        }
        return false;
      });
    },
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
