import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
import { scrumTaskSchema } from '@/services/scrumTasks';
/* Sprints */

export const scrumSprintApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      sprint_new(sprint: ${stringifyWithNoQuote(params)}) {
        id
        active
        tasks {
          ${scrumTaskSchema}
          sprint {
            id
          }
        }
        createdAt
        updatedAt
        currentState
      }
    }
  `,
  );
};

export const scrumSprintApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      sprint_edit(sprint: ${stringifyWithNoQuote(params)}, closeToBacklog: ${
      params.closeToBacklog
    }) {
        id
        startAt
        endAt
        active
        tasks {
          ${scrumTaskSchema}
          sprint {
            id
          }
        }
        createdAt
        updatedAt
        currentState
      }
    }
  `,
  );
};

export const scrumSprintApiClose = (params = null) => {
  return graphqlApi(
    `mutation {
      sprint_edit(sprint: ${stringifyWithNoQuote(params)}, closeToBacklog: ${
      params.closeToBacklog
    }) {
        id
      }
    }
  `,
  );
};

export const scrumSprintApiReadForSearchBar = (params = null) => {
  return graphqlApi(
    `{
      sprints{
        id,
        name,
        startAt,
        endAt
      }
  }
  `,
  );
};
