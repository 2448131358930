import {
  ticketApiCreate,
  ticketApiDelete,
  ticketApiRead,
  ticketApiUpdate,
} from '@/services/tickets';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('ticket', {
  create: ticketApiCreate,
  read: ticketApiRead,
  update: ticketApiUpdate,
  delete: ticketApiDelete,
});

export const ticketStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
