import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

// Check Pay history

export const checkPayHistoryRead = (params = null) => {
  return graphqlApi(
    `{
      checkPayHistories(period: "${params ? params.period : null}") {
        periodHistory,
        createdAt,
      },
    }`,
  );
};
