import {
  maintenanceContractCounterApiCreate,
  maintenanceContractCounterApiDelete,
  maintenanceContractCounterApiRead,
  maintenanceContractCounterApiUpdate,
} from '@/services/maintenanceContractCounters';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('maintenanceContractCounter', {
  create: maintenanceContractCounterApiCreate,
  read: maintenanceContractCounterApiRead,
  update: maintenanceContractCounterApiUpdate,
  delete: maintenanceContractCounterApiDelete,
});

export const maintenanceContractCounterStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
