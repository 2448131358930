<template>
  <div id="app">
    <!-- Element UI in action -->
    <el-container class="pr-h-screen">
      <el-aside width="60px">
        <Navigation v-if="$store.getters.roles" />
      </el-aside>
      <el-container class="pr-p-0">
        <el-main class="pr-p-0 pr-flex pr-flex-col">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Navigation from '@/components/organisms/Navigation.vue';

export default {
  name: 'App',
  data() {
    return {
      links: {},
    };
  },
  components: {
    Navigation,
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
fieldset {
  border: 1px solid config('colors.grey');
  padding: config('pristine.gutterUnit.base') !important;
  padding-bottom: 0 !important;
  margin-bottom: config('pristine.spaceUnit.base') !important;
  legend {
    padding: 0 config('pristine.gutterUnit.base') !important;
  }
}

.el-form-item__label {
  line-height: 1.5 !important;
}

.el-dialog__wrapper {
  overflow: hidden !important;
}

.el-dialog {
  margin-top: 5vh !important;
  margin-bottom: 5vh !important;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  min-width: 600px;
  .el-dialog__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: config('pristine.gutterUnit.base');
    border-bottom: 1px solid config('colors.grey-lighter');
  }
  .el-dialog__body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .el-dialog__footer {
    padding: config('pristine.gutterUnit.base');
    border-top: 1px solid config('colors.grey-lighter');
  }
  .el-dialog__headerbtn {
    position: static !important;
    outline: 0 !important;
  }
  @media (max-width: 630px) {
    min-width: 100%;
  }
}
.el-date-editor {
  display: inline-flex;
  justify-content: space-between;
}
</style>
