import { graphqlApi, graphApiUpload } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
/* Ticket Files */

export const ticketFileApiCreate = (params = null) => {
  let request = {
    query:
      'mutation($file: FileInput!) { ticketFile_new(ticketFile: { file:$file } ) { id, originalName }} ',
    variables: { file: null },
  };
  let map = {
    '0': ['variables.file'],
  };
  const fd = new FormData();
  fd.append('operations', JSON.stringify(request));
  fd.append('map', JSON.stringify(map));
  fd.append('0', params);

  return graphApiUpload(fd);
};

export const ticketFileApiRead = (params = null) => {
  return graphqlApi(
    `{
      ticketFiles {
        id
        originalName
      }
    }`,
  );
};

export const ticketFileApiDelete = (params = null) => {
  console.log(params);
  return graphqlApi(
    `mutation {
      ticketFile_delete(ticketFile: ${stringifyWithNoQuote(params)}) {
        id
      }
    }`,
  );
};
