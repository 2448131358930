import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

/* Ticket Categories */

export const ticketCategoryApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_category_new(ticket_category: ${stringifyWithNoQuote(params)}) {
        id
        title
        createdAt
        updatedAt
      }
    }`,
  );
};

export const ticketCategoryApiRead = (params = null) => {
  return graphqlApi(
    `{
      ticketCategories {
        id
        title
        createdAt
        updatedAt
      }
    }`,
  );
};

export const ticketCategoryApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_category_edit(ticket_category: ${stringifyWithNoQuote(params)}) {
        id
        title
        createdAt
        updatedAt
      }
    }`,
  );
};

export const ticketCategoryApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_category_delete(ticket_category: ${stringifyWithNoQuote(params)}) {
        id
      }
    }`,
  );
};
