import { graphqlApi, graphApiUpload, api } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
import axios from 'axios';
/* Absences */

export const absenceApiCreate = (params = null) => {
  return graphqlApi(
    `
      mutation {
        absence_new(absence: ${stringifyWithNoQuote(params)}) {
          id
          startDate
          endDate
          type {
            id
          }
          user {
            id
          }
          frequencyType
          regular
          occurrence
          repetition
          currentState
          olderState
          availableStates
          filePath
          fileOriginalName
          address
          createdAt
          updatedAt
        }}
    `,
  );
};

export const absenceApiRead = (params = null) => {
  return graphqlApi(
    `{absences (user: ${stringifyWithNoQuote(
      params ? params.user : null,
    )}, period: ${stringifyWithNoQuote(params ? params.period : null)}) {
          id
          startDate
          endDate
          type {
            id
            label
          }
          user {
            id
          }
          frequencyType
          regular
          occurrence
          repetition
          currentState
          olderState
          availableStates
          filePath
          fileOriginalName
          address
          createdAt
          updatedAt
        }}
    `,
  );
};

export const absenceApiUpdate = (params = null) => {
  return graphqlApi(
    `
      mutation {
        absence_edit(absence: ${stringifyWithNoQuote(params)}) {
          id
          startDate
          endDate
          type {
            id
          }
          user {
            id
          }
          frequencyType
          regular
          occurrence
          repetition
          currentState
          olderState
          availableStates
          filePath
          fileOriginalName
          address
          createdAt
          updatedAt
        }}
    `,
  );
};

export const absenceApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
        absence_delete(absence: {id: ${stringifyWithNoQuote(params)}}) {
          id
        }}
    `,
  );
};

export const absenceTypesApiRead = (params = null) => {
  return graphqlApi(
    `{
      absenceTypes {id, label}
    }
  `,
  );
};

export const publicHollidaysApiRead = () => {
  return axios.get(
    'https://calendrier.api.gouv.fr/jours-feries/metropole.json',
  );
};
