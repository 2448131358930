import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

/* Teams */

export const teamApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      team_new(team: ${stringifyWithNoQuote(params)}) {
        id
        name
        parent {
          id
        }
        users {
          id
        }
        createdAt
        updatedAt
      }
    }
  `,
  );
  // return api.post(template('/teams/')(params), params);
};

export const teamApiRead = (params = null) => {
  return graphqlApi(
    `{teams {
         id
            name
            parent {
              id
            }
            users {
              id
            }
            createdAt
            updatedAt
      }}
    `,
  );
  // return api.get(template('/teams/')(params), params);
};

export const teamApiUpdate = (params = null) => {
  return graphqlApi(`
    mutation {
      team_edit(team: ${stringifyWithNoQuote(params)}) {
        id
        name
        parent {
          id
        }
        users {
          id
        }
        createdAt
        updatedAt
      }
    }
  `);
  // return api.put(template('/teams/${id}')(params), params);
};

export const teamApiDelete = (params = null) => {
  return graphqlApi(`
    mutation {
      team_delete(team: {id: ${stringifyWithNoQuote(params)}}) {
        id
      }
    }
  `);
  // return api.delete(template('/teams/${id}')({ id: params }), params);
};
