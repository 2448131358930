import {
  ticketCategoryApiCreate,
  ticketCategoryApiRead,
  ticketCategoryApiUpdate,
  ticketCategoryApiDelete,
} from '@/services/ticketCategories';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('ticketCategory', {
  create: ticketCategoryApiCreate,
  read: ticketCategoryApiRead,
  update: ticketCategoryApiUpdate,
  delete: ticketCategoryApiDelete,
});

export const ticketCategoryStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
