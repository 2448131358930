import { api } from '@/consts';
import {
  taskApiCreate,
  taskApiDelete,
  taskApiRead,
  taskApiUpdate,
} from '@/services/tasks';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('task', {
  create: taskApiCreate,
  read: taskApiRead,
  update: taskApiUpdate,
  delete: taskApiDelete,
});

export const taskStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
