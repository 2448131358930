import { graphqlApi } from '@/consts';

/* Tasks */

export const taskApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      task_new(task: ${params}) {
         id
         name
         startDate
         endDate
         progress
         project
         parentId
         assignee {
          id
         }
         charge
         hasKanban
         createdAt
         updatedAt
      }}
    `,
  );
  // return api.post(template('/tasks/')(params), params);
};

export const taskApiRead = (params = null) => {
  return graphqlApi(
    `{tasks {
         id
         name
         startDate
         endDate
         progress
         project
         parentId
         assignee {
          id
         }
         charge
         hasKanban
         createdAt
         updatedAt
      }}
    `,
  );
  // return api.get(template('/tasks/')(params), params);
};

export const taskApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      task_edit(task: ${params}) {
         id
         name
         startDate
         endDate
         progress
         project
         parentId
         assignee {
          id
         }
         charge
         hasKanban
         createdAt
         updatedAt
      }}
    `,
  );
  // return api.put(template('/tasks/${id}')(params), params);
};

export const taskApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      task_delete(task: ${params}) {
         id
         name
         startDate
         endDate
         progress
         project
         parentId
         assignee {
          id
         }
         charge
         hasKanban
         createdAt
         updatedAt
      }}
    `,
  );
  // return api.delete(template('/tasks/${id}')({ id: params }), params);
};
