import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
/* scrumBoards */
import { scrumTaskSchema } from '@/services/scrumTasks';

const scrumBoardSchema = `
  name,
  id,
  active,
  currentSprint{
    id,
  }
  assignee {
    id
  }
  unassignedUser {
    id
  }
  teams {
    id
  }
  createdAt
  updatedAt
`;

export const scrumBoardApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      scrum_board_new(scrum_board: ${stringifyWithNoQuote(params)}) {
        ${scrumBoardSchema}
      }
    }`,
  );
};

export const scrumBoardApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      scrum_board_edit(scrum_board: ${stringifyWithNoQuote(params)}){
        ${scrumBoardSchema}
      }
    }`,
  );
};

export const scrumBoardApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      scrum_board_delete(scrum_board: {id: ${stringifyWithNoQuote(params)}}) {
        id
      }}
    `,
  );
};

export const scrumBoardApiReadListView = (params = null) => {
  return graphqlApi(
    `{
      scrumBoards{
        ${scrumBoardSchema}
      }
    }`,
  );
};

export const scrumBoardApiReadById = (id = null) => {
  return graphqlApi(
    `{scrumBoard(id: ${id}) {
        name
        id
        users {
          id
          username
          avatar
        }
        tasksInBacklog {
          ${scrumTaskSchema}
          ticket {
            id
          }
        }
        currentSprint {
          id
          active
          startAt
          endAt
          tasks {
            ${scrumTaskSchema}
            sprint {
              id
            }
            ticket {
              id
            }
          }
          createdAt
          updatedAt
          currentState
        }
      }}`,
  );
};

export const scrumBoardApiReadByIdGetSprints = (id = null) => {
  return graphqlApi(
    `{scrumBoard(id: ${id}) {
          sprints {
            id
            tasks {
            ${scrumTaskSchema}
            }
          }
        }}`,
  );
};

export const scrumBoardApiReadByIdGetUsers = (id = null) => {
  return graphqlApi(
    `{scrumBoard(id: ${id}) {
          users {
            id
            username
          }
        }}`,
  );
};
