import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

export const scrumTaskSchema = `
  id
  name
  priority
  description
  assignee {
    id
  }
  scrumBoard {
    id
  }
  elapsedTime
  status {
    id
  }
  project {
    id
    name
  }
  consumer {
    id
    name
  }
  isBacklog
  storyPoints
  referenceStoryPoints
  createdAt
  updatedAt
  completedAt
  createdBy {
    id
  }
`;

// Create a scrumTask
export const scrumTaskApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
        scrum_task_new(scrum_task: ${stringifyWithNoQuote(params)}) {
            ${scrumTaskSchema}
    }}`,
  );
};

// Read a scrumTask
export const scrumTaskApiReadById = (id = null) => {
  return graphqlApi(
    `{
        scrumTask(id: ${id}) {
            ${scrumTaskSchema}
        }}`,
  );
};

// Update a scrumTask
export const scrumTaskApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
        scrum_task_edit(scrum_task: ${stringifyWithNoQuote(params)}) {
            ${scrumTaskSchema}
    }}`,
  );
};

// Delete a scrumTask
export const scrumTaskApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
        scrum_task_delete(scrum_task: ${stringifyWithNoQuote(params)}) {
            id
        }}`,
  );
};

// Move a scrumTask form sprint and backlog (depending of sprint parameters)
export const scrumTaskApiMove = ({ tasks = [], sprint = null }) => {
  return graphqlApi(
    `mutation {
      scrum_task_move_sprint_backlog(scrum_tasks : ${stringifyWithNoQuote(
        tasks,
      )}, sprint: ${stringifyWithNoQuote(sprint)}) {
        ${scrumTaskSchema}
      }
    }`,
  );
};

export const scrumTaskStatusApiRead = (params = null) => {
  return graphqlApi(
    `{scrumTasksStatus {
            id
            name
        }}`,
  );
};
