import {
  ticketFileApiCreate,
  ticketFileApiRead,
  ticketFileApiDelete,
} from '@/services/ticketFiles';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('ticketFile', {
  create: ticketFileApiCreate,
  read: ticketFileApiRead,
  delete: ticketFileApiDelete,
});

export const ticketFileStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
