import axios from 'axios';
import { orderBy } from 'lodash';

export const graphStore = {
  namespaced: true,
  state: {
    sprint: null,
    error: '',
    sprintTasks: [],
    filterActive: false,
    filteringSprintTasks: false,
    sprintGraphData: null,
    graphIsLoading: false,
  },
  getters: {
    error: (state) => {
      return state.error;
    },
    sprint: (state) => {
      return state.sprint;
    },
    filterActive: (state) => {
      return state.filterActive;
    },
    graphIsLoading: (state) => {
      return state.graphIsLoading;
    },
    getSprintTasks: (state) => {
      return state.sprintTasks;
    },
    getFilteringSprintTasks: (state) => {
      return state.filteringSprintTasks;
    },
    getGraphTasksFilterValues: (state) => {
      return state.graphTasksFilterValues;
    },
    getSprintGraphData: (state) => {
      return state.sprintGraphData;
    },
  },
  mutations: {
    setSprint: (state, data) => {
      state.sprint = data;
      state.sprintTasks = data.sprintHistories;
    },
    filterSprintTasks: (state) => {
      state.filteringSprintTasks = true;
    },
    filterSprintTasksSuccess: (state, data) => {
      state.sprintTasks = data;
      state.filteringSprintTasks = false;
      state.filterActive = true;
    },
    filterSprintTasksFailure: (state) => {
      state.filteringSprintTasks = false;
    },
    setGraphTasksFilterValues: (state, data) => {
      state.graphTasksFilterValues = data;
    },
    startSetSprintGraphData: (state) => {
      state.graphIsLoading = true;
    },
    setSprintGraphDataSuccess: (state, data) => {
      state.sprintGraphData = data;
      state.graphIsLoading = false;
    },
    setSprintGraphDataFailure: (state) => {
      state.graphIsLoading = false;
    },
    setFilterActive: (state, value) => {
      state.filterActive = value;
    },
  },
  actions: {
    fetchSprintGraphData: ({ state, commit }) => {
      commit('startSetSprintGraphData');
      return new Promise((resolve, reject) => {
        return axios
          .get(`/admin/api/scrum/sprints/chart/${state.sprint.id}`)
          .then((res) => {
            commit('setSprintGraphDataSuccess', res.data);
            resolve(res.data);
          })
          .catch((err) => {
            commit('setSprintGraphDataFailure');
            reject(err);
          });
      });
    },
    fetchHistoryTasks: ({ state, commit }, params) => {
      commit('filterSprintTasks');
      return new Promise((resolve, reject) => {
        return axios
          .post('/admin/api/scrum/tasks/historyTasks', params)
          .then((res) => {
            commit('filterSprintTasksSuccess', res.data);
            resolve(res.data);
          })
          .catch((err) => {
            commit('filterSprintTasksFailure');
            reject(err);
          });
      });
    },
    // fetchAllSprints({dispatch, state}, data) {
    //   return new Promise((resolve, reject) => {
    //     return axios.get('/admin/api/scrum/sprints/all')
    //   });
    // }
  },
};
