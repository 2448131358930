const pristineConfig = {
  unit: {
    base: '40px',
  },
  gutterUnit: {
    base: '20px',
  },
  spaceUnit: {
    xs: '5px',
    sm: '10px',
    base: '20px',
    lg: '30px',
    xl: '40px',
  },
  screens: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    '2xl': '1400px',
  },
  gutters: {
    base: '15px',
  },
  colors: {
    grey: '#f6f6f6',
    'base-text-color': '#22292f',
    'base-bg-color': '#f8fafc',
    'base-border-color': '#dae1e7',
    danger: '#e3342f',
    warning: '#f6993f',
    success: '#38c172',
    secondary: '#3490dc',
    info: '#3490dc',
    primary: '#9561e2',

    'grey-darkest': '#3d4852',
    'grey-darker': '#606f7b',
    'grey-dark': '#8795a1',
    'grey-light': '#dae1e7',
    'grey-lighter': '#f1f5f8',
    'grey-lightest': '#f5f5f5',
  },
  spacing: {
    form: '22px',
    lg: '25px',
    base: '15px',
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {
    base: '2px',
    lg: '8px',
  },
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {},
  fontSize: {
    lg: '18px',
  },
  fontWeight: {},
  height: {},
  inset: {},
  letterSpacing: {},
  lineHeight: {},
  listStyleType: {},
  margin: {
    xs: '5px',
    sm: '10px',
    base: '20px',
    lg: '30px',
    xl: '40px',
  },
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  width: {},
  zIndex: {},
};

module.exports = pristineConfig;
