import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

/* Tickets */

export const ticketApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_new(ticket: ${stringifyWithNoQuote(params)}) {
        id
        current_state
        available_status
        title
        description
        due_date
        time_tracking
        priority
        category {
          id
          title
        }
        consumer {
          id
          name
        }
        creator {
          id
          username
        }
        files {
          id
          originalName
        }
        scrum_tasks {
          id
          scrumBoard {
            id
          }
        }
        updatedAt
      }
    }`,
  );
};

export const ticketApiRead = (params = null) => {
  return graphqlApi(
    `{
      tickets {
        id
        current_state
        available_status
        title
        description
        due_date
        time_tracking
        priority
        category {
          id
          title
        }
        consumer {
          id
          name
        }
        creator {
          id
          username
        }
        files {
          id
          originalName
          path
        }
        scrum_tasks {
          id
          name
          storyPoints
          elapsedTime
          status {
            id
          }
          assignee {
            id
          }
          scrumBoard {
            id
          }
        }
        updatedAt
      }
    }`,
  );
};

export const ticketApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_edit(ticket: ${stringifyWithNoQuote(params)}) {
        id
        current_state
        available_status
        title
        description
        due_date
        time_tracking
        priority
        category {
          id
          title
        }
        consumer {
          id
          name
        }
        creator {
          id
          username
        }
        files {
          id
          originalName
        }
        scrum_tasks {
          id
          scrumBoard {
            id
          }
        }
        updatedAt
      }
    }`,
  );
};

export const ticketApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      ticket_delete(ticket: ${stringifyWithNoQuote(params)}) {
        id
      }
    }`,
  );
};
