import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import i18n from '@/i18n';
import router from './router';
import store from './store';
import './plugins/element.js';
import uuid from 'uuid/v1';
import 'nprogress/nprogress.css';
import 'vue-context/dist/css/vue-context.css';
import { get } from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueClipboard from 'vue-clipboard2';

import '@/assets/favicon.png';

import { getTicketsUploadDir } from './consts';

let ColorHash = require('color-hash');
let colorHash = new ColorHash({ lightness: 0.8 });

Vue.config.productionTip = false;
Vue.prototype.$uuid = uuid;
Vue.prototype.$get = get;
Vue.prototype.$log = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
  return args[0];
};
Vue.prototype.$getTicketsUploadDir = getTicketsUploadDir;
Vue.prototype.$stringToHex = (string) => {
  return colorHash.hex(string);
};

Vue.use(CKEditor);
Vue.use(VueI18n);

if (document.querySelector('#app')) {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
  Vue.use(VueClipboard);
}
