import { api } from '@/consts';
import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  kanbanTaskApiCreate,
  kanbanTaskApiRead,
  kanbanTaskApiUpdate,
  kanbanTaskApiDelete,
} from '@/services/kanbans';

const store = new StoreCRUD('kanbanTask', {
  create: kanbanTaskApiCreate,
  read: kanbanTaskApiRead,
  update: kanbanTaskApiUpdate,
  delete: kanbanTaskApiDelete,
});

export const kanbanTaskStore = {
  namespaced: true,
  state: {
    ...store.state,
    kanbanTaskColumn: null,
  },
  getters: {
    ...store.getters,
    getTasksByKanbanId: (state: any) => (kanbanId: number) => {
      return state.kanbanTasks.filter(
        (kanbanTask) => kanbanId == kanbanTask.kanban,
      );
    },
    getKanbanTaskColumn(state) {
      return state.kanbanTaskColumn;
    },
  },
  mutations: {
    ...store.mutations,
    ['UPDATE_KANBANTASK_COLUMN'](state, id) {
      state.kanbanTaskColumn = id;
    },
  },
  actions: {
    ...store.actions,
    move({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        return api
          .put(`/kanbans/tasks/update-position/${data.id}`, {
            position: data.position,
            column_id: data.columnId,
          })
          .then((res) => {
            dispatch('read').then(() => {
              resolve(res.data);
            });
          })
          .catch((err) => {
            commit('CREATING_KANBANTASK_ERROR', err);
            reject();
          });
      });
    },
  },
};
