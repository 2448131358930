import { graphqlApi, graphApiUpload } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';
/* PayHistory */

export const payHistoryApiCreate = (params = null) => {
  return graphqlApi(
    `
      mutation {
        pay_history_new(pay_history: ${stringifyWithNoQuote(params)}) {
          id
          user {
            id
          }
          absences {
            id
            type {
              id
              label
            }
            startDate
            endDate
            filePath
          }
          updatedAt
          periodHistory
        }}
    `,
  );
};

export const payHistoryApiRead = (params = null) => {
  return graphqlApi(
    `{payHistories(user: ${params ? params.user : null}, period: "${
      params ? params.period : null
    }") {
          id
          user {
            id
          }
          absences {
            id
            type {
              id
              label
            }
            startDate
            endDate
            filePath
          }
          updatedAt
          periodHistory
        }}
    `,
  );
};

export const payHistoryApiUpdate = (params = null) => {
  return graphqlApi(
    `
      mutation {
        pay_history_edit(pay_history: ${stringifyWithNoQuote(params)}) {
          id
          user {
            id
          }
          absences {
            id
          }
          updatedAt
          periodHistory
        }}
    `,
  );
};

export const payHistoryApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
        pay_history_delete(pay_history: {id: ${stringifyWithNoQuote(params)}}) {
          id
        }}
    `,
  );
};
