import { graphqlApi } from '@/consts';
import { stringifyWithNoQuote } from '@/plugins/jsonify';

/* Maintenance Contract Counters */

export const maintenanceContractCounterApiCreate = (params = null) => {
  return graphqlApi(
    `mutation {
      maintenance_contract_counter_new(maintenance_contract_counter: ${stringifyWithNoQuote(
        params,
      )}) {

    id
    title
    description
    counted_hours
    ticket {
      id
      title
    }
    maintenance_contract {
      id
      hourly_volume
    }
    created_at
    updated_at

}
    }`,
  );
};

export const maintenanceContractCounterApiRead = (params = null) => {
  return graphqlApi(
    `{
  maintenanceContractCounters {
    id
    title
    description
    counted_hours
    ticket {
      id
      title
      scrum_tasks{
        id
        elapsedTime
        scrumBoard{
          id
        }
      }
    }
    maintenance_contract {
      id
      hourly_volume
      start_date
      end_date
      client{
        id
        name
      }
    }
    created_at
    updated_at
  }
}`,
  );
};

export const maintenanceContractCounterApiUpdate = (params = null) => {
  return graphqlApi(
    `mutation {
      maintenance_contract_counter_edit(maintenance_contract_counter: ${stringifyWithNoQuote(
        params,
      )})
   {
    id
    title
    description
    counted_hours
    ticket {
      id
      title
    }
    maintenance_contract {
      id
      hourly_volume
    }
    created_at
    updated_at
  }

    }`,
  );
};

export const maintenanceContractCounterApiDelete = (params = null) => {
  return graphqlApi(
    `mutation {
      maintenance_contract_counter_delete(maintenance_contract_counter: ${stringifyWithNoQuote(
        params,
      )}) {
        id
      }
    }`,
  );
};
