import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  addressApiCreate,
  addressApiDelete,
  addressApiRead,
  addressApiUpdate,
} from '@/services/addresses';

const store = new StoreCRUD('column', {
  create: addressApiCreate,
  read: addressApiRead,
  update: addressApiUpdate,
  delete: addressApiDelete,
});

export const addressStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
