import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  absenceApiCreate,
  absenceApiRead,
  absenceApiUpdate,
  absenceApiDelete,
  absenceTypesApiRead,
  publicHollidaysApiRead,
} from '@/services/absences';

const store = new StoreCRUD('absence', {
  create: absenceApiCreate,
  read: absenceApiRead,
  update: absenceApiUpdate,
  delete: absenceApiDelete,
});

export const absenceStore = {
  namespaced: true,
  state: {
    ...store.state,
    absenceTypes: [],
    absenceTypesLoading: false,
    publicHolidays: [],
  },
  getters: {
    ...store.getters,
    absenceTypes(state: any) {
      return state.absenceTypes;
    },
    absenceTypesLoading(state: any) {
      return state.absenceTypesLoading;
    },
    publicHollidays(state: any) {
      return state.publicHolidays;
    },
  },
  mutations: {
    ...store.mutations,
    ['READ_ABSENCES_TYPE'](state) {
      state.absenceTypesLoading = true;
    },
    ['READ_ABSENCES_TYPE_SUCCESS'](state, payload) {
      state.absenceTypesLoading = false;
      state.absenceTypes = payload;
    },
    ['READ_ABSENCES_TYPE_FAILURE'](state) {
      state.absenceTypesLoading = false;
    },
    setPublicHollidays(state, payload) {
      state.publicHolidays = payload;
    },
  },
  actions: {
    ...store.actions,
    readAbsenceTypes: ({ commit, state }, params) => {
      commit('READ_ABSENCES_TYPE');
      return new Promise((resolve, reject) => {
        return absenceTypesApiRead(params)
          .then(({ absenceTypes }) => {
            commit('READ_ABSENCES_TYPE_SUCCESS', absenceTypes);
            resolve(absenceTypes);
          })
          .catch((err) => {
            commit('READ_ABSENCES_TYPE_FAILURE');
            reject(err);
          });
      });
    },
    publicHolidayRead: ({ commit, state }) => {
      // if (!state.publicHolidays.length) {
      return new Promise((resolve, reject) => {
        return publicHollidaysApiRead()
          .then(({ data }) => {
            commit('setPublicHollidays', data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  // },
};
