const stringifyWithNoQuote = (json, removeTag = false) => {
  let parsedSentence = JSON.stringify(json).replace(/\"([^(\")"]+)\":/g, '$1:');
  if (removeTag) {
    return parsedSentence.slice(1, -1);
  }
  return parsedSentence;
};

// More powerfull method

// function stringifyWithNoQuote(json){
//   if(typeof json !== "object" || Array.isArray(json)){
//     // not an object, stringify using native function
//     return JSON.stringify(json);
//   }
//   // Implements recursive object serialization according to JSON spec
//   // but without quotes around the keys.
//   let props = Object
//     .keys(json)
//     .map(key => `${key}:${stringifyWithNoQuote(json[key])}`)
//     .join(",");
//   return `{${props}}`;
// }

export { stringifyWithNoQuote };
