const checkRoles = ({ next, store, to }, requiredRoles) => {
  const roles = store.getters.roles;
  if (!roles) {
    return next({
      name: 'home',
    });
  } else {
    if (requiredRoles.find((requiredRole) => roles.includes(requiredRole))) {
      return next();
    }
    return next({
      name: 'home',
    });
  }
};

export default checkRoles;
