import StoreCRUD from '@/factory/StoreCRUD.ts';
import { checkPayHistoryRead } from '@/services/checkPayHistory';

const store = new StoreCRUD('checkPayHistory', {
  read: checkPayHistoryRead,
});

export const checkPayHistoryStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
  },
};
