import {
  maintenanceContractApiCreate,
  maintenanceContractApiDelete,
  maintenanceContractApiRead,
  maintenanceContractReadById,
  maintenanceContractApiUpdate,
} from '@/services/maintenanceContracts';
import StoreCRUD from '@/factory/StoreCRUD.ts';

const store = new StoreCRUD('maintenanceContract', {
  create: maintenanceContractApiCreate,
  read: maintenanceContractApiRead,
  update: maintenanceContractApiUpdate,
  delete: maintenanceContractApiDelete,
});

export const maintenanceContractStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
    maintenanceContractReadById({}, params) {
      return new Promise((resolve, reject) => {
        return maintenanceContractReadById(params)
          .then(({ maintenanceContract }) => {
            resolve(maintenanceContract);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
