import { api } from '@/consts';
// import { findIndex, remove, cloneDeep, find, includes } from 'lodash';
import StoreCRUD from '@/factory/StoreCRUD.ts';
import {
  kanbanApiCreate,
  kanbanApiRead,
  kanbanApiUpdate,
  kanbanApiDelete,
} from '@/services/kanbans';

const store = new StoreCRUD('kanban', {
  create: kanbanApiCreate,
  read: kanbanApiRead,
  update: kanbanApiUpdate,
  delete: kanbanApiDelete,
});

export const kanbanStore = {
  namespaced: true,
  state: {
    ...store.state,
  },
  getters: {
    ...store.getters,
  },
  mutations: {
    ...store.mutations,
  },
  actions: {
    ...store.actions,
    readById({ commit }, id) {
      return new Promise((resolve, reject) => {
        return api
          .get('kanbans/' + id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject();
          });
      });
    },
  },
};
